.text-small {
  font-size: 10px !important;
}
.card.job-category-card {
  border: 1px solid #ddd;
  /* Add a subtle border */
  border-radius: 8px;
  /* Rounded corners for a smoother appearance */
  transition: box-shadow 0.3s ease;
  /* Smooth transition for hover effect */
}

.card.job-category-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add a subtle shadow on hover for depth */
}

.card-body {
  display: flex;
  align-items: center;
}

.item-logo {
  /* Add any specific styles for your logo or icon */
  margin-right: 15px;
  /* Adjust spacing between logo and text */
}

.card-title {
  margin-bottom: 0.5rem;
  /* Provide spacing below the title */
}

.font-xs {
  font-size: 0.8rem;
  /* Adjust font size for smaller text */
}